

.ant-layout-header {
  height: 80px !important;
  padding: 16px 70px;
  background: radial-gradient(
    27266.28% 35.56% at 46.56% 62.5%,
    #fba33b 0%,
    #ff8e21 100%
  );
}
.form-input-select {
  height: 40px;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  /* 14 Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--black-20, rgba(0, 0, 0, 0.2));
  padding: 4px 0px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
  background: var(--white-100, #fff);
  box-shadow: none !important;
  .ant-select-selection-item {
    line-height: 30px !important;
  }
}
.complete-status {
  padding: 8px;
  width: fit-content;
  border-radius: 8px;
  background: #06adde;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.form-input {
  height: 40px;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  /* 14 Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--black-20, rgba(0, 0, 0, 0.2));
  padding: 8px 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
  background: var(--white-100, #fff);
  box-shadow: none !important;
  .ant-input-affix-wrapper {
    border-radius: 4px;
    &:hover {
      border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1)) !important;
    }
  }
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #087392 !important;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  border-color: #ff9024 !important ;
}
.ant-form-item-explain-error{
  color: #087392 !important;
}
// .ant-input:hover {
//   border: 1px solid white !important;
// }
.agent-main-container {
  height: 100vh;
  width: 100vw;

  .form-input-select {
    height: 40px;
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--black-20, rgba(0, 0, 0, 0.2));
    padding: 4px 0px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
    background: var(--white-100, #fff);
    box-shadow: none !important;
    .ant-select-selection-item {
      line-height: 30px !important;
    }
  }
  .ant-layout {
    height: 100%;

    .ant-layout-header {
      height: 80px !important;
      padding: 0px 70px 0px 20px;
      background: radial-gradient(
        27266.28% 35.56% at 46.56% 62.5%,
        #fba33b 0%,
        #ff8e21 100%
      );
    }

    .ant-layout-sider-has-trigger {
      width: 380px !important;
      min-width: 380px !important;
      max-width: 380px !important;
      background: #087392 !important;
      .ant-layout-sider-trigger {
        display: none;
        width: 300px;
        min-width: 300px;
        max-width: 300px;
      }

      &.ant-layout-sider-collapsed {
        width: 80px !important;
        min-width: 80px !important;
        max-width: 80px !important;
      }
    }
  }
}
.ant-float-btn {
  width: 64px !important;
  height: 64px !important;
  padding: 24px !important;
  border-radius: 67px !important;
  background: #00a5d4 !important;
  border: 1px solid #a4a4a3 !important;
  inset-inline-end: 76px !important;
  inset-block-end: 26px !important;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.15) !important;
}
.ant-float-btn-body {
  background-color: #00a5d4 !important;
}
.ant-float-btn-content {
  flex-direction: row !important;
  overflow: unset !important;
}
.ant-picker {
  width: 100%;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.main-card-container {
  border-radius: 15px;
  max-height: 75vh;
  overflow: auto;
  overflow-x: hidden;
}
.ant-btn-primary {
  padding: 8px 16px !important;
  align-self: stretch;
  border-radius: 4px !important;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  background: #ff9024;
  backdrop-filter: blur(70px) !important;
  display: flex !important;
  align-items: center !important;
  font-weight: 400 !important;
  height: auto !important;
  justify-content: center;
  
  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.5) !important;
    background: #ff9024 !important;
  }
  &:focus {
    background: #ff9024 !important;
  }
}
.ant-btn-danger {
  padding: var(--8, 8px) var(--16, 16px) !important;
  align-self: stretch;  
  border-radius: var(--8, 8px) !important;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  background: #ff9024 !important;
  backdrop-filter: blur(70px) !important;
  display: flex !important;
  align-items: center !important;
  font-weight: 600 !important;
  justify-content: center;
  height: 64px !important;
  color: white !important;
  min-width: 148px !important;
}
.ant-modal {
  .ant-modal-body {
    padding: 20px !important;
  }
  .ant-btn-primary {
    border-radius: 2px !important;
  }
  .ant-modal-content {
    background-color: #a4a4a3 !important;
    border-radius: 22px !important;
    border: 1px solid white !important;
  }
  .ant-modal-header {
    background-color: #a4a4a3 !important;
    text-align: center;
    border-radius: 22px 22px 0 0 !important;
    .ant-modal-title {
      color: white !important;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  .ant-modal-body {
    .form-input {
      height: 40px;
      font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
      margin-bottom: 20px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: var(--black-20, rgba(0, 0, 0, 0.2));
      padding: 8px 16px;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--black-10, rgba(0, 0, 0, 0.1));
      background: var(--white-100, #fff);
    }
  }
  .ant-modal-footer {
    display: none !important;
  }
  .ant-modal-close-x {
    .ant-modal-close-icon {
      color: white !important;
    }
  }
  .record-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    padding-bottom: 10px;
    margin-bottom: 22px;
    .record-label {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .user-photo {
      width: 60px;
      height: 50px;
    }
  }
  .detail-log-close-button {
    width: 76px !important;
    padding: 8px 16px !important;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: #ff9024;
    backdrop-filter: blur(70px);
    cursor: pointer;
    color: white;
    margin-left: 74% !important;
    text-align: center;
  }
}

.filters-section {
  display: flex;
  justify-content: flex-end;

  .ant-btn-primary {
    border-radius: 2px !important;
    margin-left: 20px;
  }
}

.ant-form-item-label {
  font-weight: 500;
}

.ant-menu-root {
  max-height: 100%;
  overflow: auto;
  background: #087392;
  padding-bottom: 172px;
  width: 102.3%;
}

.delete-icon {
  background: #f9e4e4;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  color: #d32628;
}

.edit-icon {
  background: #ebf0f4;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  color: #0e136d;
}

.view-icon {
  background: #ccc;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  color: #000;
}

.sp-btw {
  justify-content: space-between;
}

.upload-btn {
  background: #eee;
  border: none;
  border-radius: 4px;
  padding: 5px 30px;
  font-weight: 500;

  &:hover {
    background: #eee;
    color: #000;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e136d !important;
  border-color: #0e136d !important;
}

.approve-btn {
  padding: 4px 10px;
  color: #00c291;
  background: #ccf2e9;
  cursor: pointer;
  border-radius: 6px;
  font-size: 13px;

  .anticon {
    font-size: 11px;
  }
}

.approve-btn-disabled {
  padding: 4px 10px;
  color: #a6b0c2;
  background: #edeff2;
  border-radius: 6px;
  font-size: 13px;
  pointer-events: none;

  .anticon {
    font-size: 11px;
  }
}

.reject-btn {
  padding: 4px 10px;
  color: #d32628;
  background: #f8dfdf;
  cursor: pointer;
  border-radius: 6px;
  font-size: 13px;

  .anticon {
    font-size: 11px;
  }
}

.reject-btn-disabled {
  padding: 4px 10px;
  color: #a6b0c2;
  background: #edeff2;
  border-radius: 6px;
  font-size: 13px;
  pointer-events: none;

  .anticon {
    font-size: 11px;
  }
}

.status-btn-disabled {
  color: #a6b0c2;
  background: #edeff2;
}

.ant-layout-sider {
  // background: #2e404f !important;
}

.ant-menu-dark {
  // background: #2e404f !important;
}

.ant-menu-item-icon {
  font-size: 16px !important;
  color: #fff !important;
}

.ant-menu-title-content {
  color: #fff;
  font-size: 15px !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.ant-menu-submenu-open {
  border-left: none !important;

  .ant-menu-submenu-title {
    color: #fff !important;

    .ant-menu-item-icon {
      color: #fff !important;
    }

    .ant-menu-title-content {
      color: #fff !important;
    }
  }
}
.ant-menu-item {
  width: 240px !important;
  margin-left: 15% !important;
  margin-bottom: 12px !important;
  height: 48px !important;
}
.ant-menu-item::after {
  border-right: none !important;
}
.ant-menu-item-selected {
  border-radius: 8px;
  height: 48px !important;
  background: var(--white-10, rgba(255, 255, 255, 0.1)) !important;

  .ant-menu-title-content {
    color: #fff;
  }
}

.ant-popover-buttons {
  display: flex;
  align-items: center;

  .ant-btn-sm {
    height: 32px;
    padding: 0px 15px;
  }
}

.blue-tag {
  background: #d9ecff;
  color: #2a65a2;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
}

.red-tag {
  background: #fedada;
  color: #b33c3c;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
}

.green-tag {
  background: #def1d0;
  color: #7ba061;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
}

.yellow-tag {
  background: #e6dfb8;
  color: #645408;
  font-weight: 500;
  padding: 1px 10px;
  border-radius: 3px;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 13px;
}

.ant-btn-default {
  border-color: #0e136d !important;
  color: #0e136d !important;
  padding: 6px 20px !important;
  height: auto !important;
  font-weight: 500 !important;

  &:hover {
    border-color: #0e136d !important;
    color: #0e136d !important;
  }
}

.status-success {
  border-radius: 18px;
  width: fit-content;
  background: #def8ee;
  color: #4aa785;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  padding: 0px 6px;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.status-failed {
  background-color: #ffbdd4;
  color: #ba002b;
  padding: 3px 18px;
}

.status-pending {
  color: #59a8d4;
  padding: 0px 6px;
  border-radius: 18px;
  background: #e2f5ff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  width: fit-content;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.status-initiated {
  color: #8a8cd9;
  padding: 0px 6px;
  border-radius: 18px;
  background: #ededff;
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  width: fit-content;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}
.status-cancelled {
  background-color: #8080809e;
  color: #000000b0;
  padding: 3px 18px;
}
