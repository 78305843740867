.menu-heading {
  color: var(--white-40, rgba(255, 255, 255, 0.4));
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  margin-left: 20%;
  /* 14 Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.divider {
  width: 240px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin-left: 15%;
}
.ant-menu-sub.ant-menu-inline {
  background: #087392 !important;
}
.ant-menu-submenu-arrow {
  display: none !important;
}
.ant-menu-submenu-title {
  padding-left: 80px !important;
}
.ant-menu-item ant-menu-item-selected ant-menu-item-only-child {
  height: 28px !important;
}
