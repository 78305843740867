.login-card {
  width: 700px;
  // padding: 0px 70px;
  position: relative;
  background: #a4a4a3;
  box-shadow: none !important;
  .deposits-input-container {
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.2) !important;
    }
    .ant-select-selector {
      border: none !important;
    }
    height: 40px;
    padding: 0px 0px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 0px !important;
    border-radius: 8px;
    background: var(--white-100, #fff);
  }
  .ant-radio-inner {
    border-color: #087392;
  }
  .ant-radio-inner::after {
    background-color: #087392 !important;
  }
  .ant-form-item-label {
    color: #fff !important;
    padding: 0px;
    font-weight: 400;
    margin-bottom: 17px;
    font-family: Inter;
    font-size: 14px;
    .ant-form-item-required {
      color: #fff !important;
    }
  }

  .login-title {
    font-weight: bolder;
    font-size: 24px;
    margin-bottom: 30px;
    color: white;
    font-family: Inter;
  }
  .thankyouu-text {
    font-size: 14px;
    color: white;
    font-family: Inter;
    margin-top: 20px;
    text-decoration: underline;
  }
  .thankyou-text {
    font-size: 14px;
    color: white;
    font-family: Inter;
    margin-top: 20px;
  }
  .sin-up-text {
    color: #087392;
    cursor: pointer;
  }
  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 20px;

    .logo {
      width: 150px;
    }
  }

  .password-field {
    margin-bottom: 15px;
  }

  .forgot-password-btn-container {
    display: flex;
    justify-content: flex-start;
    margin: 0;

    .ant-btn-link {
      color: white;
      border-bottom: 1px solid white;
      font-weight: 500;
      margin-bottom: 70px;
    }
  }

  .ant-btn-lg {
    padding: 4px 30px !important;
  }
}

.verification-inputs-render {
  .verification-code-container {
    display: flex;
    width: 100%;
    column-gap: 40px;
    .one-digit-input {
      flex: 1;
      border: 1px solid #e2e8f0;
      border-radius: 8px;
      text-align: center;
      width: 40px !important;
      height: 30px;
      color: #087392;

      &:first-child {
        border-left: 1px solid #e2e8f0;
      }

      &:focus {
        outline: none !important;
        border: 1px solid #e2e8f0 !important;
        border-radius: 8px !important;
      }
    }
  }
}
